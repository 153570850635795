<template>
    <div class="productattribute">
    <a-button type="primary" @click="addRowBtn">Add</a-button>
    
      <a-table
      :rowKey="(record, index) => index"
      :pagination="false"
      :columns="columns"
      :data-source="dataattr"
  >
     <div class="attrimg" slot="img" slot-scope="text, record, index">
          <Uploadimg :url="text"  @getImgUrl='getImgUrl($event,index)' />
     </div>
        <a-select :value="text"  slot="color" slot-scope="text, record, index" @change="handleColorChange($event,index)"    style="min-width:120px;"    >
          <a-select-option v-for="item in colorData" :key="item.code" :value="item.code">
            {{item.description}}
          </a-select-option>
        </a-select>
        <a-select :value="text"  slot="size" slot-scope="text, record, index" @change="handleChange($event,index,'size')" style="min-width:120px;"    >
          <a-select-option value="null">null</a-select-option>
          <a-select-option value="XXS">XXS</a-select-option>
          <a-select-option value="XS">XS</a-select-option>
          <a-select-option value="S">S</a-select-option>
          <a-select-option value="M">M</a-select-option>
          <a-select-option value="L">L</a-select-option>
          <a-select-option value="XL">XL</a-select-option>
          <a-select-option value="2XL">2XL</a-select-option>
          <a-select-option value="3XL">3XL</a-select-option>
          <a-select-option value="4XL">4XL</a-select-option>
          <a-select-option value="5XL">5XL</a-select-option>
          <a-select-option value="NO1">NO1</a-select-option>
          <a-select-option value="NO2">NO2</a-select-option>
          <a-select-option value="NO3">NO3</a-select-option>
          <a-select-option value="NO4">NO4</a-select-option>
          <a-select-option value="NO5">NO5</a-select-option>
          <a-select-option value="NO6">NO6</a-select-option>
          <a-select-option value="NO7">NO7</a-select-option>
          <a-select-option value="NO8">NO8</a-select-option>
          <a-select-option value="NO9">NO9</a-select-option>
          <a-select-option value="NO10">NO10</a-select-option>
          <a-select-option value="NO11">NO11</a-select-option>
          <a-select-option value="NO12">NO12</a-select-option>
          <a-select-option value="2PCS">2PCS</a-select-option>
          <a-select-option value="3PCS">3PCS</a-select-option>
          <a-select-option value="4PCS">4PCS</a-select-option>
          <a-select-option value="5PCS">5PCS</a-select-option>
          <a-select-option value="10PCS">10PCS</a-select-option>
          <a-select-option value="20PCS">20PCS</a-select-option>
          <a-select-option value="100PCS">100PCS</a-select-option>
        </a-select>
        <a-input style="min-width:50px"  v-for="col in ['sku']"
        :slot="col"
        :key='col'
        slot-scope="text, record, index"
        @change="changeinput($event,index,col)"
        :value='text'
        v-decorator="[col, { rules: [{ required: true, message: 'Please input your note!' }] }]"
        />
      <a-input style="min-width:90px" :value='text' slot='weight_g' key='weight_g' title="weight_g" suffix='g' slot-scope="text, record, index" @change="changeinput($event,index,'weight_g')" v-decorator="['weight_g', { rules: [{ required: true, message: 'Please input your note!' }] }]"/>
      <a-input style="min-width:90px" :value='text' slot='length_cm' key='length_cm' title="length_cm" suffix='cm' slot-scope="text, record, index" @change="changeinput($event,index,'length_cm')" v-decorator="['length_cm', { rules: [{ required: true, message: 'Please input your note!' }] }]"/>
      <a-input style="min-width:90px" :value='text' slot='width_cm' key='width_cm' title="width_cm" suffix='cm' slot-scope="text, record, index" @change="changeinput($event,index,'width_cm')" v-decorator="['width_cm', { rules: [{ required: true, message: 'Please input your note!' }] }]"/>
      <a-input style="min-width:90px" :value='text' slot='height_cm' key='height_cm' title="height_cm" suffix='cm' slot-scope="text, record, index" @change="changeinput($event,index,'height_cm')" v-decorator="['height_cm', { rules: [{ required: true, message: 'Please input your note!' }] }]"/>
      <!--<a-input style="min-width:80px"  v-for="col in ['size','price_usd','sku','weight_g','length_cm','width_cm','height_cm']"
      :slot="col"
      :key='col'
      slot-scope="text, record, index"
      @change="changeinput($event,index,col)"
      :value='text'  suffix='g'
      v-decorator="[col, { rules: [{ required: true, message: 'Please input your note!' }] }]"
        />-->
      <a-input style="min-width:105px" :value='text' slot='product_cost' key='product_cost' title="product_cost" suffix='RMB' slot-scope="text, record, index" @change="changeinput($event,index,'product_cost')" v-decorator="['product_cost', { rules: [{ required: true, message: 'Please input your note!' }] }]"/>
      
      <span slot='inbound_shipment_cost' title="inbound_shipment_cost"  slot-scope="text, record, index">
          <!-- <table>
            <tr>
              <td><a-input v-if="text" key='inbound_shipment_cost' style="min-width:90px;background-color: #f1f1f1;" :value='text' suffix='RMB' disabled  v-decorator="['inbound_shipment_cost', { rules: [{ required: true, message: 'Please input your note!' }] }]"/></td>
              <td><a-button style="min-width:50px;" @click="calculate($event,index,'inbound_shipment_cost')">calculate</a-button></td>
            </tr>
          </table> -->

          <a-input-group compact style="min-width:250px">
            <a-input key='inbound_shipment_cost' style="width:120px;background-color: #f1f1f1;" :value='text' suffix='RMB' disabled  v-decorator="['inbound_shipment_cost', { rules: [{ required: true, message: 'Please input your note!' }] }]"/>
            <a-button style="width:80px;" @click="calculate($event,index,'inbound_shipment_cost')">calculate</a-button>
          </a-input-group>

      </span>

      <a-select :value="text" slot="electrical_outlet_type" slot-scope="text, record, index" @change="handleChange($event,index,'electrical_outlet_type')" style="min-width:120px;">
          <a-select-option value="N/A">N/A</a-select-option>
          <a-select-option value="US">US</a-select-option>
          <a-select-option value="AU">AU</a-select-option>
          <a-select-option value="UK">UK</a-select-option>
          <a-select-option value="EU">EU</a-select-option>
      </a-select>
      <a-select :value="text"  slot="voltage" slot-scope="text, record, index" @change="handleChange($event,index,'voltage')" style="min-width:120px;">
          <a-select-option value="0">No voltage/110V-220V</a-select-option>
          <a-select-option value="110">110V only</a-select-option>
          <a-select-option value="220">220V only</a-select-option>
      </a-select>
      <a-select :value="text"  slot="packaging_id" slot-scope="text, record, index" @change="handleChange($event,index,'packaging_id')" style="min-width:120px;">
        <a-select-option v-for="item in packagingData" :key="item.packaging_id" :value="item.packaging_id">
            {{item.description}}
          </a-select-option>
      </a-select>
     <a @click="deleteattrDataBtn(index)" slot="op" slot-scope="text, op, index"  >Delete</a>
  </a-table>
    </div>
</template>

<script>
const columns = [
  
  {
    title: 'Img',
    dataIndex: 'img',
     scopedSlots: { customRender: 'img' },
  },
  {
    title: 'Color',
    dataIndex: 'color',
     scopedSlots: { customRender: 'color' },
  },
  {
    title: 'Size',
    dataIndex: 'size',
     scopedSlots: { customRender: 'size' },
  },
  // {
  //   title: 'Price_usd',
  //   dataIndex: 'price_usd',
  //    scopedSlots: { customRender: 'price_usd' },
  // },
  {
    title: 'SKU',
    dataIndex: 'sku',
     scopedSlots: { customRender: 'sku' },
  },
   {
    title: 'Weight(g)*',
    dataIndex: 'weight_g',
     scopedSlots: { customRender: 'weight_g' },
  },
   {
    title: 'Length(cm)*',
    dataIndex: 'length_cm',
     scopedSlots: { customRender: 'length_cm' },
  },
   {
    title: 'Width(cm)*',
    dataIndex: 'width_cm',
     scopedSlots: { customRender: 'width_cm' },
  },
   {
    title: 'Hieght(cm)*',
    dataIndex: 'height_cm',
     scopedSlots: { customRender: 'height_cm' },
  },{
    title: 'Shipping Cost(RMB)*',
    dataIndex: 'inbound_shipment_cost',
     scopedSlots: { customRender: 'inbound_shipment_cost' },
  },
  {
    title: 'Price(RMB)*',
    dataIndex: 'product_cost',
     scopedSlots: { customRender: 'product_cost' },
  },
  {
    title: 'Electrical outlet type*',
    dataIndex: 'electrical_outlet_type',
     scopedSlots: { customRender: 'electrical_outlet_type'},
  },
  {
    title: 'Voltage*',
    dataIndex: 'voltage',
     scopedSlots: { customRender: 'voltage'},
  },
  {
    title: 'Packaging*',
    dataIndex: 'packaging_id',
     scopedSlots: { customRender: 'packaging_id'},
  },
  {
    title: 'Op',
    dataIndex: 'op',
     scopedSlots: { customRender: 'op' },
  },
];


import Uploadimg from '@/components/content/UploadImgAttribute'
import {getProductColors,getPackagingData,getCalculate} from '@/network/prorequest'
var prodataobj={
"product_variations":[
  {"sku":"aaa","length_cm":"1","width_cm":"2","height_cm":"3","weight_g":"4","color":"BL","size":"XL","price_usd":"2.99","img":"http:\/\/www.google.com\/1.jpg"},
{"sku":"bbb","length_cm":"9","width_cm":"8","height_cm":"7","weight_g":"6","color":"RD","size":"XXL","price_usd":"4.34","img":"http:\/\/www.google.com\/5.jpg"}],
"imgs":[{"prority":1,"img":"http:\/\/www.google.com\/6.jpg"},{"prority":2,"img":"http:\/\/www.google.com\/7.jpg"}],
"title":"titlexxxx",
"is_published":0,
"description":"descriptionxxx",
"category_id":123}

export default {
    name: 'NodeprojectProductattribute',
    components: { Uploadimg },
    directives: {  },
    props:{
      attributedata:{type:Array,default:[]},
      productData:{type:Object,default:{}},
    },
    watch:{
      attributedata(){
            this.dataattr=this.$props.attributedata
      },
    },
    data() {
        return {
            productData:this.$props.productData,
            dataattr:this.$props.attributedata,
            columns,
            loading: false,
            imageUrl: '',
            colorData:{},
            sizeData:[{'code':'null'}],
            packagingData:{}
        };
    },
    created(){
       getProductColors()
        .then(res=>{
          // console.log(res)
          if(res.header.code==200){
              this.colorData=res.body.product_colors
              return ;
          }
        });
      getPackagingData()
        .then(res=>{
          // console.log(res)
          if(res.header.code==200){
              this.packagingData=res.body.packagings
              return ;
          }
      });

    },
    mounted() {},
    methods: {
      calculate(e,index,text){
        console.log(this.productData);
        // console.log(text);
        let weight_g  = this.dataattr[index].width_cm;
        let length_cm = this.dataattr[index].length_cm;
        let width_cm  = this.dataattr[index].width_cm;
        let height_cm = this.dataattr[index].height_cm;

        let with_battery = this.productData['with_battery'];
        let with_powder  = this.productData['with_powder'];
        let with_liquid  = this.productData['with_liquid'];

        if(weight_g && length_cm && width_cm && height_cm && with_powder && with_powder && with_liquid){
          getCalculate(weight_g,length_cm,width_cm,height_cm,with_battery,with_powder,with_liquid).then(res=>{
            if(res.header.code==200){
              this.dataattr[index].inbound_shipment_cost= res.body.shipping_cost;
              this.$emit('getAttributeData', {attributedata:this.dataattr});
            }else{
              this.$message.error(res.header.msg);
            }
          });
        }else{
          this.$message.error('Missing parameter');
        }
      },
      getImgUrl(e,index){
        this.dataattr[index].img=e;
        this.$emit('getAttributeData', {attributedata:this.dataattr});
      },
      changeinput(e,index,text){
        this.dataattr[index][text]=e.target.value;
        this.$emit('getAttributeData', {attributedata:this.dataattr});
      },
      addRowBtn(){
          console.log(this.colorData[0].code)
          this.dataattr.push({
                img:'',
                color:this.colorData[0].code,//默认第一个
                size: this.sizeData[0].code,
                price_usd:'',
                sku:'',
                length_cm:'',
                height_cm:'',
                width_cm:'',
                weight_g:'',
                product_cost:'',
                inbound_shipment_cost:'',
                electrical_outlet_type:'',
                voltage:'',
                packaging_id:'',
          });
        },
        //删除行
        deleteattrDataBtn(index){
          var tem = this.dataattr;
           console.log(tem);
           this.dataattr.splice(index,1);
           console.log(this.dataattr);
           this.$emit('getAttributeData', {attributedata:this.dataattr});
        },
        handleColorChange(e,index){
          this.dataattr[index].color=e;
          this.$emit('getAttributeData', {attributedata:this.dataattr});
        },
        handleChange(e,index,key){
          this.dataattr[index][key]=e;
          this.$emit('getAttributeData', {attributedata:this.dataattr});
        },
    },
};
</script>

<style scoped>
.attrimg{width:100px;height: 100px;}
.productattribute .avatar-uploader > .ant-upload {
  width: 50px;
  height: 50px;
}
.ant-upload-select-picture-card i {
  font-size: 32px;
  color: #999;
}

.ant-upload-select-picture-card .ant-upload-text {
  margin-top: 8px;
  color: #666;
}
</style>