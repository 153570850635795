<template>
    <div>
        <a-page-header
            class="header_title"
            :title="title"
            @back="() => $router.go(-1)"
        />
        <a-form :form="form" :label-col="{ span: 4 }" :wrapper-col="{ span: 18 }" @submit="handleSubmit">
            <a-form-item label="Category">
                <a-select
                    v-decorator="[
                    'category_id',
                    { rules: [{ required: true, message: 'Please select!' }] },
                    ]"
                    placeholder="Please select"
                >
                <a-select-option v-for="item in categoryData"  :key="item.category_id"  :value="item.category_id">
                    {{item.category_name}}
                </a-select-option>
                </a-select>
            </a-form-item >
            <a-form-item label="Title">
            <a-input
                placeholder="title"
                v-decorator="[
                    'title',
                    { rules: [{ required: true, message: 'Please select!' }] },
                ]"
            />
            </a-form-item>
            <a-form-item label="Product name (Chinese)">
            <a-input
                placeholder="Product name (Chinese)"
                v-decorator="[
                    'cn_name',
                    { rules: [{ required: true, message: 'Product name (Chinese)' }] },
                ]"
            />
            </a-form-item>

            <a-form-item label="Risk">
                <a-select
                    v-decorator="[
                    'risk',
                    { rules: [{ required: true, message: 'Please select!' }] },
                    ]"
                    placeholder="Please select!"
                >
                <a-select-option value="high">high</a-select-option>
                <a-select-option value="low">low</a-select-option>
                </a-select>
            </a-form-item >

            <a-form-item label="Is seasonal product?">
                <a-select
                    v-decorator="[
                    'is_seasonal',
                    { rules: [{ required: true, message: 'Please select !' }] },
                    ]"
                    placeholder="Please select!"
                >
                <a-select-option value="1">yes</a-select-option>
                <a-select-option value="0">no</a-select-option>
                </a-select>
            </a-form-item >

            <a-form-item label="Custom label (Chinese)">
            <a-input
                placeholder=""
                v-decorator="[
                    'product_custom_label_cn',
                    { rules: [{ required: true, message: 'Please input!' }] },
                ]"
            />
            </a-form-item>

            <a-form-item label="Custom label (English)">
            <a-input
                placeholder="Custom label (English)"
                v-decorator="[
                    'product_custom_label_en',
                    { rules: [{ required: true, message: 'Please input!' }] },
                ]"
            />
            </a-form-item>

            <!-- <a-form-item label="Primary Keyword(英文)*">
            <a-input
                placeholder=""
                v-decorator="[
                    'primary_keyword',
                    { rules: [{ required: true, message: 'primary_keyword' }] },
                ]"
            />
            </a-form-item> -->
            <a-form-item label="Days to dispatch">
            <!-- <a-input
                placeholder=""
                v-decorator="[
                    'lead_time_day',
                    { rules: [{ required: true, message: '到货时间(天)不能为空' }] },
                ]"
            /> -->
                <a-select
                    v-decorator="[
                    'lead_time_day',
                    { rules: [{ required: true, message: 'Please select !' }] },
                    ]"
                    placeholder="Please select"
                >
                <a-select-option value="1">1</a-select-option>
                <a-select-option value="2">2</a-select-option>
                <a-select-option value="3">3</a-select-option>
                <a-select-option value="4">4</a-select-option>
                <a-select-option value="5">5</a-select-option>
                <a-select-option value="10">10</a-select-option>
                </a-select>
            </a-form-item>
            
            <a-form-item label="Contain battery?">
                <a-select
                    v-decorator="[
                    'with_battery',
                    { rules: [{ required: true, message: 'Please select !' }] },
                    ]"
                    placeholder="Please select"
                    @change="changeSelect($event,'with_battery')"
                >
                <a-select-option value="0">No battery</a-select-option>
                <a-select-option value="1">Embedded battery</a-select-option>
                <a-select-option value="2">External battery</a-select-option>
                <a-select-option value="3">Pure battery</a-select-option>
                </a-select>
            </a-form-item >

            <a-form-item label="Contain powder">
                <a-select
                    v-decorator="[
                    'with_powder',
                    { rules: [{ required: true, message: 'Please select !' }] },
                    ]"
                    placeholder="Please select"
                    @change="changeSelect($event,'with_powder')"
                >
                <a-select-option value="1">yes</a-select-option>
                <a-select-option value="0">no</a-select-option>
                </a-select>
            </a-form-item >

            <a-form-item label="Contain liquid?">
                <a-select
                    v-decorator="[
                    'with_liquid',
                    { rules: [{ required: true, message: 'Please select !' }] },
                    ]"
                    placeholder="Please select"
                    @change="changeSelect($event,'with_liquid')"
                >
                <a-select-option value="1">yes</a-select-option>
                <a-select-option value="0">no</a-select-option>
                </a-select>
            </a-form-item >

            <a-form-item label="has CE label?">
                <a-select
                    v-decorator="[
                    'has_ce_label',
                    { rules: [{ required: true, message: 'Please select !' }] },
                    ]"
                    placeholder="Please select"
                >
                <a-select-option value="1">yes</a-select-option>
                <a-select-option value="0">no</a-select-option>
                </a-select>
            </a-form-item >

           <a-form-item  label="Variations">
               <Product-attribute @getAttributeData='getAttributeData' :attributedata="attributedata" :productData="productData" />
           </a-form-item>

            <a-form-item label="Imgs" style="margin-bottom:0;">
                <a-upload
                    action="https://shady.ecomgear.cn/kenneth_testing/maker/img/uploadPic.php"
                    list-type="picture-card"
                    :file-list="fileList"
                    :data="{maker_id:'1'}"
                    @preview="handlePreview"
                    @change="handleChange"
                    :multiple="true"
                    @reject="rejectImgs"
                    :before-upload="beforeUpload"
                    >
                    <div v-if="fileList.length < 12">
                        <a-icon type="plus" />
                        <div class="ant-upload-text">
                        Upload
                        </div>
                    </div>
                    </a-upload>
                    <a-modal :visible="previewVisible" :footer="null" @cancel="handleCancel">
                    <img alt="example" style="width: 100%" :src="previewImage" />
                    </a-modal>
            </a-form-item>
            <a-form-item label="Description*" style="margin-bottom:0;">
                    <Editor  
                         id="tinymce" v-model="tinymceHtml" :init="editorInit"></Editor>
            </a-form-item>
            <a-form-item label="Specification*" style="margin-bottom:0;">
                    <Editor  
                         id="spec" v-model="specHtml" :init="editorInit"></Editor>
            </a-form-item>
            <a-form-item label="Inside*" style="margin-bottom:0;">
                    <Editor  
                         id="inside" v-model="insideHtml" :init="editorInit"></Editor>
            </a-form-item>
            <a-form-item align="center" :wrapper-col="{ span: 12, offset: 5 }">
            <a-button type="primary" html-type="submit">
                Submit
            </a-button>
            </a-form-item>
        </a-form>
    </div>
</template>

<script>


import Editor from '@tinymce/tinymce-vue'
import tinymce from 'tinymce/tinymce'
import ProductAttribute from './childencomponents/ProductAttribute'


import 'tinymce/themes/silver/theme'
import 'tinymce/skins/ui/oxide/skin.min.css'
import 'tinymce/skins/ui/oxide/content.inline.min.css'
import "tinymce/themes/silver";
import "tinymce/plugins/image";
import "tinymce/plugins/link";
import "tinymce/plugins/code";
import "tinymce/plugins/table";
import "tinymce/plugins/lists";
import "tinymce/plugins/contextmenu";
import "tinymce/plugins/wordcount";
import "tinymce/plugins/colorpicker";
import "tinymce/plugins/textcolor";
import 'tinymce/icons/default';

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
}

import {getProductCategories,addProduct,editProduct,getProductDetail} from '@/network/prorequest'


export default {
    name: 'NodeprojectEditproduct',
    components: { Editor,ProductAttribute },
    directives: {  },
    data() {
        return {
            title:'add',
            formLayout: 'horizontal',
            form: this.$form.createForm(this, { name: 'coordinated' }),
            previewVisible: false,
            previewImage: '',
            fileList: [],
            imgs:[],
            product_id:"",
            productData:{
                description: "",
                imgs: [],
                is_published: "0",
                category_id: "0",
                product_variations: [],
                title: "",
                with_battery:'',
                with_powder:'',
                with_liquid:'',
            },
            tinymceHtml:"",
            specHtml:"",
            insideHtml:"",
            // cn_name:'',
            // risk:'',
            // is_seasonal:'',
            // product_custom_label_cn:'',
            // product_custom_label_en:'',
            // lead_time_day:'',
            // with_battery:'',
            // with_powder:'',
            // with_liquid:'',
            // has_ce_label:'',
            attributedata:[],
            categoryData:{},
            editorInit: {
                      height: 300, 
                      plugins:"link lists image code table colorpicker textcolor wordcount contextmenu",
                      content_css:'/public/skins/content/default/content.css',
                      skin_url: '/public/skins/ui/oxide',
                }
        };
    },
    created(){
        // console.log('created')

        // getProfile(1)
        // .then(res=>{
        //     console.log(res)
        // })
        // .catch(e=>{
        //     console.log(e)
        // })

    },
    created(){
        getProductCategories()
         .then(res=>{
            if(res.header.code==200){
              this.categoryData=res.body.product_categories
            // console.log(this.categoryData)
              return ;
          }
        })

        if(this.$route.query.product_id){
            this.title ='edit';
            getProductDetail(this.$route.query.product_id)
            .then(res=>{
                if(res.header.code==200){
                let product=res.body.product
                // console.log(product)
                this.form.setFieldsValue({
                    category_id:product.category_id,
                    title:product.title,
                    cn_name:product.cn_name,
                    risk:product.risk,
                    is_seasonal:product.is_seasonal,
                    product_custom_label_cn:product.product_custom_label_cn,
                    product_custom_label_en:product.product_custom_label_en,
                    lead_time_day:product.lead_time_day,
                    with_battery:product.with_battery,
                    with_powder:product.with_powder,
                    with_liquid:product.with_liquid,
                    has_ce_label:product.has_ce_label,
                })
                this.productData['with_battery'] = product.with_battery;
                this.productData['with_powder']  = product.with_powder;
                this.productData['with_liquid']  = product.with_liquid;

                this.attributedata=product.product_variations

                this.tinymceHtml=product.description;
                this.specHtml=product.spec;
                this.insideHtml=product.inside;

                this.fileList=product.imgs.map((item,index)=>{ 
                    return  {
                        uid: index,
                        name: 'image.png',
                        status: 'done',
                        url: item.img,
                        }
                })
                return ;
            }
            });
        }
    },
    mounted() {
        tinymce.init({})
    },
    methods: {
        changeSelect(e,text){
            // console.log(e);
            // console.log(text);
            this.productData[text] = e;
        },
        changeInput(value){
            console.log(value);
        },
         handleSubmit(e) {
            e.preventDefault();
            var that = this;
            this.form.validateFields((err, values) => {
                // console.log(err)
                if (!err) {
                //  console.log('Received values of form: ', values);
                
                 let imgs=this.fileList.map((item,index)=>{
                     return {
                         priority:index,
                         img:item.url||item.response.body.url
                     }
                 })
                 let obj=values;
                 obj.product_variations=this.attributedata;
                 console.log(this.attributedata);
                 const h = this.$createElement;
                 var tem = [];
                 var tem2 = [];
                 if(!this.tinymceHtml){
                   tem.push(h('p',' Description is null'));
                 }
                if(!this.specHtml){
                   tem.push(h('p',' Specification is null'));
                 }
                  if(!this.insideHtml){
                   tem.push(h('p',' Inside is null'));
                 }
                 if(!this.attributedata.length){
                   tem.push(h('p',' Variations is null'));
                 }
                 for(var i = 0; i < this.attributedata.length; i++) {
                     if(!this.attributedata[i]['img']){
                         tem.push(h('p','ln '+(i+1)+' img is null'));
                     }
                     if(!this.attributedata[i]['color']){
                         tem.push(h('p','ln '+(i+1)+' color is null'));
                     }
                     if(!this.attributedata[i]['size']){
                         tem.push(h('p','ln '+(i+1)+' size is null'));
                     }
                     if(this.attributedata[i]['size'] && this.attributedata[i]['color']){
                         if(tem2[(this.attributedata[i]['size']+this.attributedata[i]['color'])]){
                             that.error(tem2[(this.attributedata[i]['size']+this.attributedata[i]['color'])]+',ln '+(i+1)+' same property');
                            return;
                         }else{
                            tem2[(this.attributedata[i]['size']+this.attributedata[i]['color'])] = 'ln '+(i+1);
                         }
                    }
                     if(!this.attributedata[i]['height_cm'])tem.push(h('p','ln '+(i+1)+' height is null'));
                     if(!this.attributedata[i]['length_cm'])tem.push(h('p','ln '+(i+1)+' length is null'));
                    //  if(!this.attributedata[i]['price_usd'])tem.push(h('p','ln '+(i+1)+' price is null'));
                     if(!this.attributedata[i]['weight_g'])tem.push(h('p','ln '+(i+1)+' weight is null'));
                     if(!this.attributedata[i]['width_cm'])tem.push(h('p','ln '+(i+1)+' width is null'));

                     if(!this.attributedata[i]['product_cost'])tem.push(h('p','ln '+(i+1)+' product_cost is null'));
                     if(!this.attributedata[i]['inbound_shipment_cost'])tem.push(h('p','ln '+(i+1)+' inbound_shipment_cost is null'));
                     if(!this.attributedata[i]['electrical_outlet_type'])tem.push(h('p','ln '+(i+1)+' electrical_outlet_type is null'));
                     if(!this.attributedata[i]['voltage'])tem.push(h('p','ln '+(i+1)+' voltage is null'));
                     if(!this.attributedata[i]['packaging_id'])tem.push(h('p','ln '+(i+1)+' packaging_id is null'));
                 }
                if(tem.length){
                    that.error(tem);
                    return;
                }
                 obj.description=this.tinymceHtml;
                 obj.spec=this.specHtml;
                 obj.inside=this.insideHtml;
                 obj.imgs=imgs;
                 let data={product:obj};
                console.log(data);
                // return ;
                if(this.$route.query.product_id){
                    data.product_id=this.$route.query.product_id
                     //编辑更新产品by product_id
                    editProduct(data)
                    .then(res=>{
                        console.log(res);
                        if(res.header.code==200){
                            this.$message.success("success!");
                            this.$router.back(-1);
                            return ;
                        }
                        this.$message.success(res.header.msg)
                    })
                    return ;
                }

                //添加产品
                 addProduct(data)
                 .then(res=>{
                    if(res.header.code==200){
                    this.$message.success("success!");
                    this.$router.back(-1)
                        return ;
                    }
                    this.$message.success(res.header.msg);
                 })
                }
            });
        },
        error(content) {
            const h = this.$createElement;
            this.$error({
                title: 'This is an error message',
                content: h('div', {}, content),
            });
        },

    handleCancel() {
      this.previewVisible = false;
    },
    //放大图片
    async handlePreview(file) {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj);
      }
      this.previewImage = file.url || file.preview;
      this.previewVisible = true;
    },
    handleChange(info) {
        // console.log(info)
        this.fileList=info.fileList 
        //  if(info.file.status==="uploading"){
        //   this.fileList=info.fileList 
        // }
        // if(info.file.status==="removed"){
        //     this.fileList=info.fileList 
        // }
        // if(info.file.status==='done'){
           
        // }
    },
    beforeUpload(file) {
        var that = this;
        const isJPG = file.type === 'image/jpg';
        const isJPEG = file.type === 'image/jpeg';
        const isPNG = file.type === 'image/png';
        const bigLt2M = file.size / 1024 / 1024 < 2;
        return new Promise((resolve, reject) => {
            if (!(isJPG || isJPEG || isPNG)) {
            that.$message.error('You can only upload JPG file!');
            reject(file);
            }else if (!bigLt2M) {
            that.$message.error('Image must smaller than 2MB!');
            reject(file);
            }else {
            resolve(file && this.checkImageWH2(file, 1200, 1200));
            }
      });
    },
    checkImageWH2(file, width, height) {
      var that = this;
      return new Promise(function(resolve, reject) {
        let filereader = new FileReader();
        filereader.onload = e => {
          let src = e.target.result;
          const image = new Image();
          image.onload = function() {
            // 获取图片的宽高，并存放到file对象中
            console.log('file width :' + this.width);
            console.log('file height :' + this.height);
            file.width = this.width;
            file.height = this.height;
            if (this.width == width && this.height == height)
            {
              resolve();
            } else {
              that.$message.error('Please upload '+width+'px*'+height+'px image');
              reject();
            }
          };
          image.onerror = reject;
          image.src = src;
        };
        filereader.readAsDataURL(file);
      });
    },
    rejectImgs(fileList){
        // console.log(fileList)
    },
    handleSizeChange(value) {
      console.log(`Selected: ${value}`);
    },
    getAttributeData(e){
        // console.log(e.attributedata)
        this.attributedata=e.attributedata
    },
 },
};
</script>

<style  scoped>

</style>